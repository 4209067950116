import { FIRESTORE_COLLECTIONS, PROJECT_FEATURE_FLAGS } from '@cta-pond/constants';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { onIdTokenChanged } from 'firebase/auth';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { useEffect } from 'react';
import {
    Outlet,
    RouterProvider,
    createBrowserRouter,
    redirect,
    useNavigate,
    useNavigation,
    useRouteError,
} from 'react-router-dom';

// Project imports
import AdminLayout from 'components/AdminLayout';
import AdminSuperUserGuard from 'components/AdminSuperUserGuard';
import FeatureGuard from 'components/FeatureGuard';
import FullScreenLoader from 'components/FullScreenLoader';
import MainLayout from 'components/MainLayout';
import { ConfigProvider } from 'contexts/ConfigContext';
import { LayoutProvider } from 'contexts/LayoutContext';
import { orderBy } from 'firebase/firestore/lite';
import { getDecoratedProjectsForLoggedInUser, getLoggedInUser, getLoggedInUserWithCustomClaims } from 'helpers/auth';
import { getAllDocumentsInCollection } from 'helpers/firestore';
import ThemeCustomization from 'themes';
import LoginPage from 'views/LoginPage';
import MatchingJobsIndexPage from 'views/MatchingJobsIndexPage';
import NewMatchingJobPage from 'views/NewMatchingJobPage';
import NewRETLJobPage from 'views/NewRETLJobPage';
import NewSourcePage from 'views/NewSourcePage';
import NewSyncPage from 'views/NewSyncPage';
import RETLJobsIndexPage from 'views/RETLJobsIndexPage';
import SourcesIndexPage from 'views/SourcesIndexPage';
import SyncSchedulePage from 'views/SyncSchedulePage';
import SyncsIndexPage from 'views/SyncsIndexPage';
import WelcomePage from 'views/WelcomePage';
import { auth } from './firebase';

async function requireAuthedUser({ request }) {
    if (!request) {
        throw Error('No request property in provided route context object');
    }

    const loggedInUser = await getLoggedInUser();

    if (!loggedInUser) {
        const { url } = request;
        const redirectRoute = new URL(url)?.pathname ?? '';
        const maybeRedirectParams = redirectRoute ? `?${new URLSearchParams({ redirectRoute })?.toString()}` : '';

        throw redirect('/login' + maybeRedirectParams);
    }

    return loggedInUser;
}

function ErrorBoundary() {
    const error = useRouteError();
    // eslint-disable-next-line no-console
    console.error(error);
    return <h2>{error.message}</h2>;
}

const router = createBrowserRouter([
    {
        path: '/',
        id: 'root',
        Component: () => {
            const { state } = useNavigation();
            const navigate = useNavigate();

            // This listener will redirect user if they log out in a separate tab
            useEffect(() => {
                const unsubscribe = onIdTokenChanged(auth, async (user) => {
                    await auth.authStateReady();
                    if (!user) {
                        navigate('/login');
                    }
                });

                return () => {
                    unsubscribe();
                };
            }, [navigate]);

            if (state === 'loading') {
                return (
                    <>
                        <FullScreenLoader />
                    </>
                );
            }

            return <Outlet />;
        },
        children: [
            {
                index: true,
                loader: async () => redirect('/projects'),
            },
            {
                path: 'login',
                element: <LoginPage />,
                loader: async () => {
                    const loggedInUser = await getLoggedInUser();

                    if (loggedInUser) {
                        throw redirect('/projects');
                    }

                    return null;
                },
            },
            {
                path: 'admin',
                id: 'admin',
                loader: async () => {
                    const loggedInUser = await getLoggedInUserWithCustomClaims();
                    return { loggedInUser };
                },
                element: (
                    <AdminLayout>
                        <AdminSuperUserGuard />
                    </AdminLayout>
                ),
                errorElement: <ErrorBoundary />,
                children: [
                    {
                        path: 'sync-schedule',
                        element: <SyncSchedulePage />,
                        loader: async () => {
                            const syncs = await getAllDocumentsInCollection(
                                FIRESTORE_COLLECTIONS.ADMIN_SYNCS,
                                orderBy('lastExecutionDate', 'desc'),
                            );

                            return { syncs };
                        },
                    },
                ],
            },
            {
                path: 'projects',
                id: 'projects',
                loader: async () => {
                    const loggedInUser = await getLoggedInUserWithCustomClaims();
                    return { loggedInUser };
                },
                errorElement: <ErrorBoundary />,
                children: [
                    {
                        index: true,
                        loader: async () => {
                            const allProjectsForUser = await getDecoratedProjectsForLoggedInUser();

                            const defaultProjectId = allProjectsForUser?.[0]?.id ?? null;

                            // If user has no projects, sign them out (edge case)
                            if (allProjectsForUser?.length === 0 || !defaultProjectId) {
                                return auth.signOut();
                            }

                            // Go to syncs page by default. Feature flag guard will redirect if needed
                            return redirect(`/projects/${defaultProjectId}/syncs`);
                        },
                    },
                    {
                        path: ':projectId',
                        id: 'projectRoot',
                        element: <MainLayout />,
                        loader: async (context) => {
                            const {
                                params: { projectId },
                            } = context;

                            await requireAuthedUser(context);

                            const allProjectsForUser = await getDecoratedProjectsForLoggedInUser();

                            const activeProjectDetails = allProjectsForUser.find((project) => project.id === projectId);

                            if (!activeProjectDetails) {
                                throw Error('Project not found');
                            }

                            const syncPointConfigurations = (
                                await getAllDocumentsInCollection(FIRESTORE_COLLECTIONS.SYNC_POINT_CONFIGURATIONS)
                            )?.reduce((allSyncPointConfigs, syncPointConfig) => {
                                return {
                                    ...allSyncPointConfigs,
                                    [syncPointConfig.id]: syncPointConfig,
                                };
                            }, {});

                            return {
                                activeProjectDetails,
                                allProjectsForUser,
                                syncPointConfigurations,
                            };
                        },
                        errorElement: <ErrorBoundary />,
                        children: [
                            {
                                path: 'syncs',
                                loader: async (context) => {
                                    await requireAuthedUser(context);
                                    return null;
                                },
                                element: (
                                    <FeatureGuard featureFlagKey={PROJECT_FEATURE_FLAGS.IS_HOPPER_SYNCS_PAGE_ENABLED} />
                                ),
                                children: [
                                    {
                                        index: true,
                                        loader: async (context) => {
                                            const {
                                                params: { projectId },
                                            } = context;

                                            // TODO: if needed, denormalize source / destination data in syncs collection to eliminate extra calls
                                            const [syncs, sources, destinations] = await Promise.all([
                                                getAllDocumentsInCollection(`projects/${projectId}/syncs`),
                                                getAllDocumentsInCollection(`projects/${projectId}/sources`),
                                                getAllDocumentsInCollection(
                                                    FIRESTORE_COLLECTIONS.UNIVERSAL_DESTINATIONS,
                                                ),
                                            ]);

                                            const decoratedSyncs = syncs.map((sync) => {
                                                const source = sources.find(
                                                    (source) => source.path === sync.sourceDocPath,
                                                );
                                                const destination = destinations.find(
                                                    (destination) => destination.path === sync.destinationDocPath,
                                                );

                                                return {
                                                    ...sync,
                                                    source,
                                                    destination,
                                                };
                                            });

                                            return { syncs: decoratedSyncs };
                                        },
                                        element: <SyncsIndexPage />,
                                    },
                                    {
                                        path: ':syncId',
                                        id: 'syncRoot',
                                        element: <NewSyncPage />,
                                        loader: async (context) => {
                                            const {
                                                params: { projectId },
                                            } = context;

                                            const sources = await getAllDocumentsInCollection(
                                                `projects/${projectId}/sources`,
                                                orderBy('type'),
                                            );

                                            const universalDestinations = await getAllDocumentsInCollection(
                                                FIRESTORE_COLLECTIONS.UNIVERSAL_DESTINATIONS,
                                            );

                                            return { sources, destinations: universalDestinations };
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'sources',
                                loader: async (context) => {
                                    await requireAuthedUser(context);
                                    return null;
                                },
                                element: (
                                    <FeatureGuard
                                        featureFlagKey={PROJECT_FEATURE_FLAGS.IS_HOPPER_SOURCES_PAGE_ENABLED}
                                    />
                                ),
                                errorElement: <ErrorBoundary />,
                                children: [
                                    {
                                        index: true,
                                        loader: async (context) => {
                                            const {
                                                params: { projectId },
                                            } = context;
                                            const sources = await getAllDocumentsInCollection(
                                                `projects/${projectId}/sources`,
                                            );

                                            return { sources };
                                        },
                                        element: <SourcesIndexPage />,
                                    },
                                    {
                                        path: 'new/:sourceType',
                                        element: <NewSourcePage />,
                                    },
                                ],
                            },
                            {
                                path: 'matching-jobs',
                                loader: async (context) => {
                                    await requireAuthedUser(context);
                                    return null;
                                },
                                element: <FeatureGuard featureFlagKey={PROJECT_FEATURE_FLAGS.IS_MATCHING_ENABLED} />,
                                children: [
                                    {
                                        index: true,
                                        element: <MatchingJobsIndexPage />,
                                    },
                                    {
                                        path: 'new',
                                        element: <NewMatchingJobPage />,
                                    },
                                ],
                            },
                            {
                                path: 'retl-jobs',
                                loader: async (context) => {
                                    await requireAuthedUser(context);
                                    return null;
                                },
                                element: <FeatureGuard featureFlagKey={PROJECT_FEATURE_FLAGS.IS_VAN_RETL_ENABLED} />,
                                children: [
                                    {
                                        index: true,
                                        element: <RETLJobsIndexPage />,
                                    },
                                    {
                                        path: 'new',
                                        element: <NewRETLJobPage />,
                                    },
                                ],
                            },
                            {
                                loader: async (context) => {
                                    await requireAuthedUser(context);
                                    return null;
                                },
                                path: 'welcome',
                                element: <WelcomePage />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);

export default function App() {
    return (
        <ConfigProvider>
            <ThemeCustomization>
                <LayoutProvider>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        action={(snackbarId) => (
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => closeSnackbar(snackbarId)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )}
                    >
                        <RouterProvider router={router} fallbackElement={<FullScreenLoader />} />
                    </SnackbarProvider>
                </LayoutProvider>
            </ThemeCustomization>
        </ConfigProvider>
    );
}
