import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PageHeader from 'components/PageHeader';
import { convertFirestoreTimestampToDate } from 'helpers/firestore';
import { useLoaderData } from 'react-router-dom';

export default function SyncSchedulePage() {
    const { syncs } = useLoaderData();

    return (
        <>
            <PageHeader title="Sync Schedule" subtitle="View sync details across partners and platforms" />
            <section>
                <TableContainer
                    component={Paper}
                    sx={{ maxHeight: '85vh', border: 1, borderColor: 'grey.300', borderBottom: 0 }}
                    square
                >
                    <Table sx={{ minWidth: 650 }} aria-label="Syncs table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Sync ID</TableCell>
                                <TableCell>Partner</TableCell>
                                <TableCell>Source</TableCell>
                                <TableCell>Schedule</TableCell>
                                <TableCell>Last run</TableCell>
                                <TableCell>Last run status</TableCell>
                                <TableCell>Next run</TableCell>
                                <TableCell>Tags</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {syncs.map((sync) => (
                                <TableRow key={sync.id}>
                                    <TableCell>{sync.id}</TableCell>
                                    <TableCell>{sync.partnerName}</TableCell>
                                    <TableCell>{sync.vendorName}</TableCell>
                                    <TableCell>{sync.scheduleInterval}</TableCell>
                                    <TableCell>{convertFirestoreTimestampToDate(sync.lastExecutionDate)}</TableCell>
                                    <TableCell>{sync.lastState}</TableCell>
                                    <TableCell>{convertFirestoreTimestampToDate(sync.nextDAGRun)}</TableCell>
                                    <TableCell>{sync.tags?.map((tag) => tag.name)?.join(', ') ?? '-'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </section>
        </>
    );
}
